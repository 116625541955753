import { render, staticRenderFns } from "./gadgets.vue?vue&type=template&id=347f6c91&"
import script from "./gadgets.vue?vue&type=script&lang=js&"
export * from "./gadgets.vue?vue&type=script&lang=js&"
import style0 from "@/style/gadgets.less?vue&type=style&index=0&lang=less&rel=stylesheet%2Fless&"


/* normalize component */
import normalizer from "!../../node_modules/_vue-loader@15.9.7@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports