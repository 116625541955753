import axios from '../libs/gadgetsAjaxRequest';

//获取账号信息
const getUserInfo = (data) => {
    return axios.request({
        url: 'api/customerassistant/GetInfo',
        method: 'post',
        data
    })
};

//获取小程序太阳码
const qrCoder = (data) => {
    return axios.request({
        url: 'https://coupon.hnliangzhu.top/mini/common/qrcode',
        method: 'post',
        data
    })
};

//获取风水测评二维码
const cpQrcode = (data) => {
    return axios.request({
        url: 'api/customerassistant/getshare',
        method: 'post',
        data
    })
}

export{
    getUserInfo,
    qrCoder,
    cpQrcode
}