<template>
    <div id="gadgets" :class="isVertical == 'vertical' && 'gadgets'">
        <van-row class="gadgetsRow">
            <van-col span="5" :class="isVertical == 'vertical' ? 'gadgetsLeft position01 verticalGadgetsLeft' : 'gadgetsLeft position01'" v-if="position != 1">
                <van-sidebar v-model="activeKey" @change="onChangeSidebar">
                    <van-sidebar-item v-for="(item, index) in info.appids" :key="index" v-if="item == 2 || item == 3 || item == 9" :title="navName(item)" />
                </van-sidebar>
            </van-col>

            <van-col span="19" class="gadgetsRight">
                <div v-if="cardArrs" @touchstart="playerTouchStart" @touchmove="playerTouchMove" @touchend="playerTouchEnd" @mousedown="playerTouchStart" @mousemove="playerTouchMove" @mouseup="playerTouchEnd" :class="isVertical == 'vertical' ? 'container vertical' : 'container'">
                    <div class="card-wrapper">
                        <div
                        :class="isVertical ? 'card-item card-itemVertical' : 'card-item'"
                        v-for="(item, index) in cardArrs"
                        :ref="item.name"
                        :key="index"
                        :style="[{ zIndex: item.zIndex }, { transform: `scale(${item.scale}) translate3d(0px, ${item.translateY}, 0px)` },{ transition: `transform ${item.transitionTime}s ease 0s` }]">
                            <div :class="`item-inner  ${item.name}`">
                                <img :src="item.url" alt="">
                            </div>
                        </div>
                    </div>
                </div>
               
            </van-col>
        

            <van-col span="5" :class="isVertical == 'vertical' ? 'gadgetsLeft position01 verticalGadgetsLeft' : 'gadgetsLeft position01'" v-if="position == 1">
                <van-sidebar v-model="activeKey" @change="onChangeSidebar">
                    <van-sidebar-item v-for="(item, index) in info.appids" :key="index" v-if="item == 2 || item == 3 || item == 9" :title="navName(item)" />
                </van-sidebar>
            </van-col>

        </van-row>
    </div>
</template>

<script>
import {getUserInfo, qrCoder, cpQrcode} from '../api/gadgets'
import QRCode from 'qrcode'
export default {
    data () {
        return {
            shoptoken: '',
            activeKey: 0,
            position:'',
            info:'', //账号信息 appid列表， 1：良助摇优惠 2：AI计算器 3：风水测评 9：摇摇有礼
            appids:'', 
            qrCodeData:'',
            cardArrs: '',
            isClick: true,
            startY: 0, // 触摸位置
            endY: 0, // 结束位置
            moveY: 0, // 滑动时的位置
            disY: 0, // 移动距离
            slideDistance: 40, // 滑动触发切换还是回位的阀值
            currentIndex: 0, // 当前第一个的 index
            slideDirection: 0, // 滑动方向：0 向下，1 向上
            slideFilishDistance: 300,  // 滑动切换动画完成的距离
            isVertical: '', //是否为竖屏
        }
    },
    mounted () {
        const {token, vertical, position} = this.$route.query;
        vertical && (this.isVertical = vertical);
        position && (this.position = position);
        this.shoptoken = token;
        this.userInfo();
        // localStorage.getItem('position') ? this.position = localStorage.getItem('position') : this.position = 2;
        window.setPosition = this.setPosition;
    },
    computed: {
        navName () {
            return (appid) => {
                switch (parseInt(appid)) {
                    case 3 :
                        return '风水测评';
                    case 2 :
                        return 'AI计算器';
                    case 9 :
                        return '摇摇有礼';
                }
            }
        }
        
    },
    methods: {

        //设置position
        setPosition (position) {
            this.position = parseInt(position);
            localStorage.setItem('position',position);
        },
        //用户账号信息
        async userInfo () {
            const {shoptoken} = this;
            let res = await getUserInfo({shoptoken});
            const {data} = res;
            this.info = data; 
            this.info.appids = this.info.appids.split(',');
           /*  for (var i=0; i<this.info.appids.length; i++) {
                if (this.info.appids[i] == 3) {
                    var num = JSON.parse(JSON.stringify(this.info.appids[i]));
                    this.info.appids.splice(i,1);
                    this.info.appids.splice(0,0,num);
                    break;
                }
            } */
            this.getQrCode(this.info.appids);
        },

        //获取二维码
        async getQrCode (appids) {
            const {shopkey, juid, eid, brandid, cityid, employeekey} = this.info;
            const {shoptoken} = this;
            let width = 500;
            //设置每个码获取的入参
            let jsqData = {path: `pages/calcuator/index?uid=${shopkey}&empid=${eid}`, appid: 2, width,}; //AI计算器
            let yhData = {path: `pages/userCouponList/index?usermark=2&uid=${juid}`, appid: 1, width}; //摇优惠
            let ylData = {path: `pages/shake/index?id=${brandid}&cityid=${cityid}`, appid: 9, width}; //摇摇有礼
            // let bkData = {path: `pages/quertion/index?key=${employeekey}&id=${brandid}`, appid: 10, width}; //百科
            //获取太阳码和二维码
            let jsqRes = await qrCoder(jsqData);
            let yhRes = await qrCoder(yhData);
            let ylRes = await qrCoder(ylData);
            // let bkRes = await qrCoder(bkData);
            let cpRes = await cpQrcode({shoptoken});  //风水测评
            QRCode.toDataURL(decodeURIComponent(cpRes.data.link),{color:{dark:"#666",light:"#fff"}}).then(url => {
                //将所有的码链接push到一个数组里
                let link = '';
                let data = [];
                appids.map(num => {
                    num == 2 && data.push({url:`${jsqRes.data.path}${jsqRes.data.name}`, txt: '<p>全屋定制花多少</p> <p>扫扫便知晓</p>', name: 'bg01', img: require('../images/jsq.jpg')});
                    // num == 1 && data.push({url:`${yhRes.data.path}${yhRes.data.name}`, txt: '<p>想省钱？拼手气</p> <p>摇一摇抢优惠</p>', name: 'bg02', img: require('../images/yh.jpg')});
                    num == 3 && data.push({url:url, txt: '<p>风水重要不重要</p> <p>看看都不少</p>', name: 'bg03', img: require('../images/cp.jpg')});
                    num == 9 && data.push({url:`${ylRes.data.path}${ylRes.data.name}`, txt: '<p>想省钱？拼手气</p> <p>摇一摇抢优惠</p>', name: 'bg04', img: require('../images/yl.jpg')});
                })
                this.qrCodeData = data;
                this.qrCodeData.forEach((item, index, arr) => {
                    item.zIndex = arr.length - index;
                    switch (parseInt(index)) {
                        case 0:
                            item.translateY = '0vh';
                            item.scale = 0.9;
                            item.transitionTime = 1;
                            break;
                        case 1: 
                            item.translateY = '5vh';
                            item.scale = 0.86;
                            item.transitionTime = 1;
                            break;
                        case 2: 
                            item.translateY = '13vh';
                            item.scale = 0.76;
                            item.transitionTime = 1;
                            break;
                        case 3: 
                            item.translateY = '22vh';
                            item.scale = 0.66;
                            item.transitionTime = 1;
                            break;

                    }
                })
                this.cardArrs = this.qrCodeData;

            });
        },

        //导航切换
        onChangeSidebar (index) {
            const {currentIndex} = this;
            // console.log(index);
            // console.log(currentIndex);
            let num = index - currentIndex;
            if (num > 0) {
                let index = 0;
                 var stop = setInterval(() => {
                     if (index < num) {
                         this.slideUp(1);
                         index++
                     } else {
                         clearInterval(stop);
                     }
                 },150)
            } else {
                 let index = 0;
                 var stop = setInterval(() => {
                     if (index < Math.abs(num)) {
                         this.slideDown(1);
                         index++
                     } else {
                         clearInterval(stop);
                     }
                 },150)
            }
        },

        // 滑动开始
        playerTouchStart (ev) {
            ev = ev || event
            this.$nextTick(() => {
                this.isClick = true
                // tounches类数组，等于1时表示此时有只有一只手指在触摸屏幕
                if (ev.touches) {
                    if (ev.touches.length === 1) {
                        // 记录开始位置
                        this.startY = ev.touches[0].clientY
                        // console.log('开始触摸-startY', this.startY)
                    }
                }
            })
        },
        // 滑动中
        playerTouchMove (ev) {
            ev = ev || event
            this.$nextTick(() => {
                this.isClick = false
                if (ev.touches) {
                    if (ev.touches.length === 1) {
                        // 滑动时距离浏览器左侧实时距离
                        this.moveY = ev.touches[0].clientY
                        // 起始位置减去实时的滑动的距离,得到手指实时偏移距离
                        this.disY = this.startY - this.moveY
                        // console.log('滑动-disY', this.disY)
                        // 判断滑动方向
                        if (this.disY < 0) {
                        // 向下滑
                        this.slideDirection = 0
                        // 当前上一个变化
                        if (this.cardArrs[this.currentIndex - 1]) {
                            let item_0 = this.cardArrs[this.currentIndex - 1]
                            item_0.translateY = -window.innerHeight - this.disY + 'px'
                            item_0.transitionTime = 0
                            if (-this.disY <= this.slideFilishDistance) {
                            item_0.scale = -(0.2 / this.slideFilishDistance) * this.disY + 0.8
                            }
                        }
                        // 当前第一个变化
                        let item_1 = this.cardArrs[this.currentIndex]
                        if (-this.disY <= this.slideFilishDistance) {
                            item_1.translateY = -(9 / this.slideFilishDistance) * this.disY + 'vh'
                            item_1.transitionTime = 0
                            item_1.scale = (0.1 / this.slideFilishDistance) * this.disY + 1
                        }
                        // 当前第二个变化
                        if (this.cardArrs[this.currentIndex + 1]) {
                            let item_2 = this.cardArrs[this.currentIndex + 1]
                            if (-this.disY <= this.slideFilishDistance) {
                            item_2.translateY = -(5 / this.slideFilishDistance) * this.disY + 9 + 'vh'
                            item_2.transitionTime = 0
                            item_2.scale = (0.05 / this.slideFilishDistance) * this.disY + 0.9
                            }
                        }
                        // 当前第三个变化
                        if (this.cardArrs[this.currentIndex + 2]) {
                            let item_3 = this.cardArrs[this.currentIndex + 2]
                            if (-this.disY <= this.slideFilishDistance) {
                            item_3.translateY = -(26 / this.slideFilishDistance) * this.disY + 14 + 'vh'
                            item_3.transitionTime = 0
                            item_3.scale = (0.35 / this.slideFilishDistance) * this.disY + 0.85
                            }
                        }
                        
                        } else if (this.disY > 0) {
                        // 向上滑
                        this.slideDirection = 1
                        // 当前第一个变化
                        this.$nextTick(() => {
                            let item_1 = this.cardArrs[this.currentIndex]
                            item_1.translateY = -this.disY + 'px'
                            item_1.transitionTime = 0
                            item_1.scale = 1
                        })
                        // 当前第二个变化
                        if (this.cardArrs[this.currentIndex + 1]) {
                            let item_2 = this.cardArrs[this.currentIndex + 1]
                            if (this.disY <= this.slideFilishDistance) {
                            item_2.translateY = -(9 / this.slideFilishDistance) * this.disY + 9 + 'vh'
                            item_2.transitionTime = 0
                            item_2.scale = (0.1 / this.slideFilishDistance) * this.disY + 0.9
                            }
                        }
                        // 当前第三个变化
                        if (this.cardArrs[this.currentIndex + 2]) {
                            let item_3 = this.cardArrs[this.currentIndex + 2]
                            if (this.disY <= this.slideFilishDistance) {
                            item_3.translateY = -(5 / this.slideFilishDistance) * this.disY + 14 + 'vh'
                            item_3.transitionTime = 0
                            item_3.scale = (0.05 / this.slideFilishDistance) * this.disY + 0.85
                            }
                        }
                        // 当前第四个变化
                        if (this.cardArrs[this.currentIndex + 3]) {
                            let item_4 = this.cardArrs[this.currentIndex + 3]
                            if (this.disY <= this.slideFilishDistance) {
                            item_4.translateY = -(26 / this.slideFilishDistance) * this.disY + 40 + 'vh'
                            item_4.transitionTime = 0
                            item_4.scale = (0.35 / this.slideFilishDistance) * this.disY + 0.5
                            }
                        }
                        }
                    }
                }
            })
        },
        // 滑动结束
        playerTouchEnd (ev) {
            ev = ev || event
            this.$nextTick(() => {
                if (ev.changedTouches) {
                        if (ev.changedTouches.length === 1) {
                            this.endY = ev.changedTouches[0].clientY
                            // console.log('滑动结束-endY', this.endY)
                            this.disY = this.startY - this.endY
                            if (Math.abs(this.disY) < this.slideDistance) {
                            // 滑动距离小于滑动限制的距离,强行回到起点
                                this.returnBack()
                            } else {
                            // 滑动距离大于滑动限制的距离,滑动到最大值
                                if (this.slideDirection === 1) {
                                this.slideUp()
                                } else {
                                this.slideDown()
                                }
                            }
                        }
                }
            })
        },
        // 回到起点
        returnBack () {
            // 当前第一个变化
            this.$nextTick(() => {

                let item_1 = this.cardArrs[this.currentIndex]
                item_1.translateY = 0
                item_1.transitionTime = 1
                item_1.scale = 1
                // 当前第二个变化
                if (this.cardArrs[this.currentIndex + 1]) {
                    let item_2 = this.cardArrs[this.currentIndex + 1]
                    item_2.translateY = '9vh'
                    item_2.transitionTime = 1
                    item_2.scale = 0.9
                }
                // 当前第三个变化
                if (this.cardArrs[this.currentIndex + 2]) {
                    let item_3 = this.cardArrs[this.currentIndex + 2]
                    item_3.translateY = '14vh'
                    item_3.transitionTime = 1
                    item_3.scale = 0.85
                }
                // 当前第四个变化
                if (this.cardArrs[this.currentIndex + 3]) {
                    let item_4 = this.cardArrs[this.currentIndex + 3]
                    item_4.translateY = '40vh'
                    item_4.transitionTime = 1
                    item_4.scale = 0.5
                }
                this.$forceUpdate()
            })
        },
        // 向上滑动切换
        slideUp (status) {
            this.$nextTick(() => {
                if (this.currentIndex === this.cardArrs.length - 1) {
                    return this.returnBack()
            }
            // 当前第一个变化
            let item_1 = this.cardArrs[this.currentIndex]
            item_1.translateY = '-160vh'
            item_1.transitionTime = 1
            item_1.scale = 0.5
            // 当前第二个变化
            if (this.cardArrs[this.currentIndex + 1]) {
                let item_2 = this.cardArrs[this.currentIndex + 1]
                item_2.translateY = 0
                item_2.transitionTime = 1
                item_2.scale = 1
            }
            // 当前第三个变化
            if (this.cardArrs[this.currentIndex + 2]) {
                let item_3 = this.cardArrs[this.currentIndex + 2]
                item_3.translateY = '9vh'
                item_3.transitionTime = 1
                item_3.scale = 0.9
            }
            // 当前第四个变化
            if (this.cardArrs[this.currentIndex + 3]) {
                let item_4 = this.cardArrs[this.currentIndex + 3]
                item_4.translateY = '14vh'
                item_4.transitionTime = 1
                item_4.scale = 0.85
            }
            this.currentIndex++
            if (this.currentIndex > this.cardArrs.length - 1) {
                this.currentIndex = this.cardArrs.length - 1
            }
            status != 1 && (this.activeKey = this.currentIndex);
            this.$forceUpdate()
            })
        },
        // 向下滑动切换
        slideDown (status) {
            this.$nextTick(() => {
                if (this.currentIndex === 0) {
                    return this.returnBack()
                }
                // 当前上一个变化
                if (this.cardArrs[this.currentIndex - 1]) {
                    let item_0 = this.cardArrs[this.currentIndex - 1]
                    item_0.translateY = 0
                    item_0.transitionTime = 0.6
                    item_0.scale = 1
                }
                // 当前第一个变化
                let item_1 = this.cardArrs[this.currentIndex]
                item_1.translateY = '9vh'
                item_1.transitionTime = 0.6
                item_1.scale = 0.9
                // 当前第二个变化
                if (this.cardArrs[this.currentIndex + 1]) {
                    let item_2 = this.cardArrs[this.currentIndex + 1]
                    item_2.translateY = '14vh'
                    item_2.transitionTime = 0.6
                    item_2.scale = 0.85
                }
                // 当前第三个变化
                if (this.cardArrs[this.currentIndex + 2]) {
                    let item_3 = this.cardArrs[this.currentIndex + 2]
                    item_3.translateY = '40vh'
                    item_3.transitionTime = 0.6
                    item_3.scale = 0.5
                }
                this.currentIndex--
                if (this.currentIndex < 0) {
                    this.currentIndex = 0
                }
                status != 1 && (this.activeKey = this.currentIndex);
                this.$forceUpdate();
            })
        },
    }
}
</script>

<style lang="less" rel="stylesheet/less" src="@/style/gadgets.less">

</style>